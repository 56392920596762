<template>
  <div class="book-detail">
    <p class="title">基于过程的质量管理体系策划</p>
    <div>
      <p class="ptittle">书本介绍</p>
      <span
        >本书介绍了以顾客为关注焦点的企业，应该如何识别管理过程，并关切以过程为基础建立质量管理体系，最终建立起以顾客为导向的管理模式。</span
      >
    </div>
    <br />
    <div>
      <p class="ptittle">内容介绍</p>
      <span
        >中国标准出版社与我司继推出《ISO14001理论与实践丛书》后又共同策划了一套《ISO9001理论与实践丛书》，本书是其中之一。</span
      >
      <br />
      <br />
      <span>
        从理念上“以顾客为中心”到操作上“以过程为中心”是ISO9001提出的重要原则，它们两者的结合就得出了“以过程为基础的质量管理体系模式”，这意味着标准回答了市场管理和过程管理的源头究竟在哪里的根本性问题。以标准的态度，推出一种管理模式也标志着它的探索取得了重要的成果，并且是得到了证实的成果。该模式对组织建立和完善体系具有深远的指导意义。
      </span>
      <br />
      <span>
        诚然，笔者在深入探讨该模式时体会到，标准确实有一句顶一万句的功效，要真正贯彻好它并非易事。加之考虑到体系的建立一直存在着以下几个方面的困难：
      </span>
      <br />
      <p>（1）基本概念的理解；</p>
      <p>（2）质量策划；</p>
      <p>（3）规范性与灵活性的处理；</p>
      <p>（4）职责和权限的合理规定；</p>
      <p>（5）文件的操作性。</p>
      <div class="divp">
        <span>因此，本书对应地也分成了5个章节；</span>
        <p><span>第一章</span><span>基本概念和原理</span></p>
        <p><span>第二章</span><span>质量管理体系策划</span></p>
        <p><span>第三章</span><span>控制的四个层次</span></p>
        <p><span>第四章</span><span>职能、职责和职权</span></p>
        <p><span>第五章</span><span>体系文件编写</span></p>
      </div>
      <br />
      <span
        >标准的思想是应该推崇的，是值得我们深入研究的，只有在领悟标准思想的情况下才能真正理解建立ISO9000体系的意义和完全明白应该有怎样的付出，这绝对不是一蹴而就的事情。对此，本书提供了一些可行的方法和大量的例子，使体系的建立更具操作性。</span
      >
      <br />
      <span
        >当组织将展望未来寄托在自己所建立的体系上时，当我们将体系看作是一件工艺品去雕琢而不再想起那是ISO9000时，那体系就一定很棒。要是这样，该书所展示的“以过程为基础的质量管理体系模式”或许能给你带来启发和帮助。</span
      >
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
@import "../../../assets/style/BookStyle.css";
</style>